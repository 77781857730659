@import "../base/variables";
@import "../base/mixins";
.searchresult {
  font-family: Amare Alta, sans-serif;
  line-height: 120%;
  letter-spacing: -1%;
  &-headline {
    font-size: 24px;
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.hiddens {
  opacity: 0;
}

.story {
  overflow-x: hidden;
  min-height: calc(100vh - 91px);
  padding-top: $header-height;
  height: 100%;
  & .share {
    color: $dark-blue;
  }

  font-family: "Minion Pro", serif;
  background: #f3f4f5;
  color: $dark-blue !important;
  line-height: 120%;
  font-size: 23px;

  .has-tiny-font-size {
    font-size: 10px;
    line-height: 1;
  }
  .has-small-font-size {
      font-size: 16px;
      line-height: 1;
  }
  .has-normal-font-size {
      font-size: 23px; // currently 23px
      line-height: 1;
  }
  .has-large-font-size {
      font-size: 30px;
      line-height: 1;
  }
  .has-extra-large-font-size {
      font-size: 36px; // headlines
      line-height: 1;
  }
  .has-huge-font-size {
      font-size: 48px;
      line-height: 1;
  }
  .has-gigantic-font-size {
      font-size: 72px;
      line-height: 1;
  }
  
  .mobile-section-wrap {
    white-space: normal;
  }

  .navbar-toggler {
    color: inherit !important;
  }


  .modern-footnotes-footnote__note {
    background: white;
    padding: 0.75rem;
    border: 1px solid black;
    border-radius: 2px;
    font-family: sans-serif;
    font-size: 17px;
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: inherit;
    }
  }

  .modern-footnotes-list {
    list-style-type:none;
    font-size:.8em;
    margin:1em 0;
    padding-left:0
  }
  .modern-footnotes-list li{
    display:flex;margin-bottom:.3em
  }
  .modern-footnotes-list li>span{
    margin-right:1.5em;
    color:#333;
    word-wrap:normal;
    word-break:normal
  }
  .modern-footnotes-list--show-only-for-print,.modern-footnotes-list-heading--show-only-for-print{display:none}@media print{.modern-footnotes-list--show-only-for-print,.modern-footnotes-list-heading--show-only-for-print{display:block}.modern-footnotes-list--hide-for-print,.modern-footnotes-list-heading--hide-for-print{display:none}}

  .story-navigation {
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    padding: 4rem 4rem;
    @media screen and (max-width: 420px) {
      padding: 2rem 2rem;
    }
    @media screen and (max-width: 350px) {
      padding: 1rem 1rem;
    }
    max-width: 80rem;
    margin: 0 auto;
    font-family: Amare Alta, sans-serif;
    font-size: 17px;
  }

  & .btn.btn-secondary {
    background-color: $dark-blue;
  }

  & .dropdown-menu {
    color: inherit;
    max-height: 28rem;
    max-width: 30rem;
    min-width: 80%;
    border: 0;
    overflow-y: scroll;
    padding: 0.5rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  & .aligncenter {
    text-align: center;
  }

  & .dropdown-menu::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  & .dropdown-menu::scrollbar-width {
    display: none;
  }

  & pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
 }

  .theme-dark & {
    color: white !important;
    background: $dark-blue;
    & .btn.btn-secondary {
      background-color: white;
    }
    & .share {
      color: white;
    }

    & .wp-block-verse {
      color: white;
    }

    .modern-footnotes-list li>span{
      margin-right:1.5em;
      color:white;
      word-wrap:normal;
      word-break:normal
    }

    .modern-footnotes-footnote__note {
      background: $dark-blue;
      padding: 0.75rem;
      border: 1px solid black;
      border-radius: 2px;
      font-family: sans-serif;
      font-size: 17px;
      position: absolute;
      opacity: 0;
      pointer-events: none;
  
      &.active {
        opacity: 1;
        pointer-events: inherit;
      }
    }

    & .dropdown-menu {
      background: $dark-blue;
    }

    & .concept_tag,
    .keyword_tag {
      border: 1px solid white !important;
    }

    & .story-header__right > * {
      color: white !important;
    }
  }

  .theme-light & {
    background-image: linear-gradient(100deg, #50E2C126 5.91%, #9039FE26 60.55%, #0076FF26 91.92%);
  }

  & a {
    color: inherit !important;
  }

  & blockquote {
    padding: 2rem 0;
    & p {
      font-style: italic;
      text-align: center;

      &:first-of-type {
        &::before {
          content: "\201c";
        }
      }

      &:last-of-type {
        &::after {
          content: "\201d";
        }
      }
    }
    & cite {
      font-style: normal;
      display: block;
      text-align: right;

      &::before {
        content: "– ";
      }
    }
  }

  .keyword_tag {
    font-weight: bold;
    border: hidden !important;
    cursor: pointer;
    border: 0px;
  }

  .concept_tag {
    border: 1px solid black;
    cursor: pointer;
    padding: 0.2rem 0.1rem;
    // windows fixes;
    padding-top: 0.1px;
    padding-bottom: 0.1px;
  }

  &-onboarding {
    @extend .searchresult;
    max-width: 100%;
    padding: 3rem 4rem;
    padding-bottom: 4rem;
    color: white !important;

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      width: 100vw;
      max-width: 50rem;
    }

    &-close {
      // max-width: 50rem;
      color: white;
      position: absolute;
      right: 3rem;
      top: 3rem;
      z-index: 1000;
    }
    & h3 {
      text-transform: uppercase;
    }

    background: linear-gradient(
      102.27deg,
      #0076ff -6.92%,
      #9039fe 31.1%,
      #50e2c1 91.03%
    );
    border-radius: 18px;

    &-item {
      display: flex !important;
      flex-direction: row;
      align-items: flex-start;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &__image {
        @extend .story-blob;
        flex-shrink: 0;
        width: 50%;
        padding-right: 2rem;

        @media screen and (max-width: 768px) {
          padding-bottom: 1rem;
        }
      }
    }

    // overriding react slick classNames

    & .slick-prev,
    .slick-next {
      position: absolute;
      bottom: 1rem;
      top: auto;
      text-transform: uppercase;
      cursor: pointer;
      z-index: 1000;
      width: auto;
      height: auto;
      font-size: inherit !important;
      line-height: inherit !important;
      color: inherit !important;
      transform: none;
      &:before {
        content: "";
      }
    }

    & .slick-disabled {
      display: none;
    }
    & .slick-prev {
      left: 2rem;
    }

    & .slick-next {
      right: 2rem;
    }

    & .slick-dots {
      display: flex;
      justify-content: center;
      right: 0;
      bottom: 1.25rem;

      & li {
        background: transparent;
        border-radius: 100%;
        color: transparent;
        width: 12px;
        height: 12px;
        border: 1px solid white;
      }

      & li button:before {
        opacity: 0;
        content: "";
      }

      & li.slick-active {
        width: 12px;
        height: 12px;
        background: white;
        color: transparent;
        border: 1px solid transparent;
        border-radius: 100%;
        opacity: 1;
      }
    }
  }

  &-spinner-wrapper {
    display: flex;
    justify-content: center;
    padding: 25rem 0;
    width: 100%;

    &--compact {
      padding: 2rem 0;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
    font-family: Amare Alta, "sans-serif";
    z-index: 500;
    position: fixed !important;
    top: $header-height;
    border-bottom: .5px solid $dark-blue;
    left: 0;

    .theme-light & {
      backdrop-filter: blur(20px);
      background-color: #f3f4f580;
    }
    .theme-dark & {
      border-color: white;
      background-color: $dark-blue;
    }

    .nav-item {
      display: inline-flex;
    }

    & .section-dropdown-toggle-mobile {
      & .nav-link {
        padding: 0;
      }
      & .dropdown-toggle.nav-link::after {
        display: none;
        content: "";
      }
      opacity: 0;
      @media screen and (max-width: 990px) {
        opacity: 1;
      }
    }

    & .btn.btn-secondary {
      padding: 0;
      color: inherit;
      width: 16px;
      height: 16px;
      border-radius: 100%;
    }

    &__right {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;
      & > * {
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        // margin-left: .25rem;
      }

      & .dropdown {
        display: flex;
        align-items: center;
      }
    }
  }

  &-breadcrumb {
    @extend .searchresult;
    color: inherit !important;
    line-height: 1.5;
    margin-bottom: 0;
    font-size: 19px;
    padding: rem(5px) rem(16px) 0;
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;
    & > span,
    & > a,
    & .dropdown > a {
      flex-shrink: 0;
      max-width: 100%;
      display: inline-block;
      margin-right: 0.75rem;
      text-transform: uppercase;
      font-weight: normal !important;
      max-width: 80vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .nav-link {
      padding: 0;
    }

    @media screen and (max-width: 990px) {
      // visually hidden but still respect flexbox
      opacity: 0;
      width: 1px;
      height: 1px;
      pointer-events: none;
    }
  }
  &-content {
    padding: 6rem 2rem;
    font-family: "Minion Pro", serif;
    max-width: 45rem;
    margin: 0 auto;
    font-size: 23px;

    & iframe,
    figure {
      display: block;
      border: 0;
      margin: 4rem auto;
      width: 100%;
      max-width: 100%;
      margin-bottom: 4rem !important;

      & figcaption {
        font-size: 14px;
        opacity: 0.6;
        text-align: right;
      }
    }

    & a {
      color: inherit !important;
      font-weight: bold;
      text-decoration: underline !important;

      &:hover {
        transition: 0.15s ease-in-out;
        opacity: 0.8;
      }
    }

    & h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .footnote_container_prepare {
      color: inherit;
      background: none;
      font-family: "Minion Pro", serif;
      margin-bottom: 4rem;
      margin-top: 4rem;
      font-size: 34px;
      font-weight: bold;
    }

    :target:before {
      content: "";
      display: block;
      height: 100px;
      margin: -50px 0 0;
    }

    & #MAIN_HEADER {
      text-transform: uppercase;
      font-family: Amare Alta, sans-serif !important;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: -1% !important;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }

    // & h2 {
    //   color: inherit;
    //   background: none;
    //   font-family: "Minion Pro", serif;
    //   margin-bottom: 1rem;
    //   margin-top: 4rem;
    //   font-size: 26px;
    //   font-weight: bold;
    // }

    & p {
      margin-bottom: 1rem;
    }

    & .footnote_index_arrow,
    .speaker-mute .accessibility {
      display: none;
    }

    & .footnotes_plugin_reference_row {
      & a {
        color: inherit;
      }
    }
  }
}

.story-blob {
  position: relative;
  &:hover {
    @media screen and (min-width: 768px) {
      .storyExcerpt {
        opacity: 1;
        z-index: 1;
      }
      & img {
        opacity: 0;
      }
    }
  }
  .storyExcerpt {
    font-size: 17px !important;
    transition: 0.15s ease-in-out;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    // width: 120%;
    text-align: center;
    height: 90%;
    overflow-y: auto;

    @media screen and (max-width: 1200px) {
      font-size: 14px !important;
    }
  }

  position: relative;
  & img {
    transition: 0.15s ease-in-out;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  &--scale {
    &--1 {
      // transform: translateY(1.5rem);
    }
    &--2 {
      transform: translate(-5%, 17%) scale(1.5);
    }
    &--3 {
      transform: scale(1.2);
    }
    &--5 {
      transform: translateY(1rem) scale(1.2);
    }
  }

  &__image {
    z-index: 2;
    position: relative;

    &--1 {
      mask: url(/svg/Bubble1-solid.svg);
    }
    &--2 {
      mask: url(/svg/Bubble5-solid.svg);
    }
    &--3 {
      mask: url(/svg/Bubble4-solid.svg);
    }
    &--4 {
      mask: url(/svg/Bubble3-solid.svg);
    }
    &--5 {
      mask: url(/svg/Bubble2-solid.svg);
    }
  }

  &__lines {
    @include absoluteCover;
    
    .blob {
      z-index: 3;
      @include absoluteCover;
    }

    &--1 .blob {
      top: -10%;
      left: 53%;
      width: 25%;
      height: 25%;
    }
    &--2 .blob {
      &:first-child {
        top: 7%;
        left: -30%;
        width: 82%;
        height: 82%;
      }
      &:last-child {
        top: 25%;
        left: 100%;
        width: 35%;
        height: 35%;
        z-index: 1;
      }
    }
    &--3 .blob {
      &:first-child {
        top: -15%;
        left: 11%;
        width: 22%;
        height: 22%;
      }
      &:last-child {
        top: 35%;
        left: 68%;
        width: 47%;
        height: 47%;
      }
    }
    &--4 .blob {
      width: 65%;
      height: 65%;
    }
    &--5 .blob {
      top: 5%;
    }
  }

  &--absolute {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }
}

.stories-favourite-container {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  & .stories-item {
    width: 50%;
    max-width: 50%;
    padding-right: 2rem;

    @media screen and (max-width: 1200px) {
      width: 100%;
      max-width: 100%;
      padding-right: 0;
    }
  }
}

.stories {
  position: relative;
  font-family: Amare Alta, sans-serif;
  font-size: 17px;
  color: $dark-blue;
  background: white;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .theme-switch {
    position: absolute;
    top: -2rem;
    right: 1rem;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  & a,
  .more-link {
    color: inherit;

    &:hover {
      color: inherit;
    }

    &:active {
      color: inherit;
    }

    &:visited {
      color: inherit;
    }
  }

  .more-link {
    z-index: 5;
  }

  .theme-dark & {
    color: white !important;
    background: $dark-blue;

    & .stories__searches {
      background: $dark-blue;

      &-apply-button-mobile {
        @extend .searchresult;
        text-align: center;
        background-color: white !important;
        color: $dark-blue !important;
      }
    }

    & input[type="text"],
    .autocomplete,
    .stories-item__title {
      border: 1px solid white !important;
    }

    & input[type="text"]::placeholder {
      color: white;
      opacity: 0.6;
    }

    & .category-tag {
      border: 1px solid rgba(255, 255, 255, 0.4) !important;
    }
  }

  .theme-light {
    /* OA gradient */
    // background: linear-gradient(153.16deg, #50E2C1 5.91%, #9039FE 52.99%, #0076FF 104.16%)
    // background-image: linear-gradient(
    //     153.16deg,
    //     rgba(80, 226, 193, 0.26) 5.91%,
    //     rgba(144, 57, 254, 0.26) 52.99%,
    //     rgba(0, 118, 255, 0.26) 104.16%
    //   ),
    //   linear-gradient(white, white);
    background-image: linear-gradient(153.16deg, #50E2C126 5.91%, #9039FE26 60.55%, #0076FF26 91.92%);
  }

  &-hero {
    flex-shrink: 0;
    background: radial-gradient(
      74.17% 94.15% at 25.83% 69.37%,
      #0076ff 0%,
      #9039fe 51.56%,
      #7b71ea 64.58%,
      #50e2c1 100%
    );
    mix-blend-mode: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    // min-height: 46rem;
    // max-height: 46rem;
    height: 50vw;
    padding: 6rem 4rem;
    // buffer for header
    padding-top: 14rem;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      padding: 0;
      padding-top: 6rem;
      align-items: stretch;
      min-height: 32rem;
      max-height: 32rem;
    }

    &-header {
      position: absolute;
      top: 5rem;
      left: 0;
      right: 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &-illustration {
      position: absolute;
      right: 2rem;
      width: 8rem;
      bottom: -2.01rem;
    }

    &-items-wrapper {
      display: flex;
      align-items: center;
      @media screen and (max-width: 1024px) {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
    &-items {
      margin: 0 auto;

      @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        height: 100%;
      }

      > a {
        position: absolute;

        @media screen and (max-width: 1024px) {
          position: static;
          width: 40% !important;
          min-width: 250px !important;
          margin-right: 5%;
        }

        &:first-child {
          top: 25%;
          left: 5%;
          width: 22%;

          h2, p {
            width: 55%;
          }
          .story-blob {
            margin-bottom: -15%;
          }
        }
        &:nth-child(2) {
          top: 60%;
          left: 25%;
          width: 18%;

          h2 {
            margin-bottom: -6%;
          }
          .storyExcerpt {
            width: 120%;
          }
        }
        &:nth-child(3) {
          top: 20%;
          left: 33%;
          width: 20%;

          h2 {
            transform: translate(80%, 0);
          }
          p {
            transform: translate(66%, 0);
          }
          .story-blob {
            transform: translateY(-11%);
          }
          .storyExcerpt {
            width: 120%;
          }
        }
        &:nth-child(4) {
          top: 52%;
          left: 52%;
          width: 24%;

          @media screen and (max-width: 1024px) {
            transform: translateX(20%);
          }

          .story-blob {
            margin-bottom: -15%;
          }
        }
        &:nth-child(5) {
          top: 25%;
          left: 75%;
          width: 22%;
          h2 {
            margin-bottom: -15%;
          }
        }
      }
    }

    &-item {
      margin: 0 1rem;
      display: flex;
      flex-direction: column;

      &--inverse {
        flex-direction: column-reverse;
      }

      & img {
        // max-height: 12rem;
        height: 100%;
        width: 100%;
        // max-width: 16rem;
        margin-bottom: 2rem;
        object-fit: cover;
        position: relative;
        z-index: 1;
        max-height: 12rem;

        @media screen and (min-width: 1025px) {
          max-height: 17vw;
        }
      }

      font-family: Amare Alta, sans-serif !important;
      line-height: 120%;
      color: white !important;
      & h2 {
        font-size: 21px;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
        text-transform: uppercase;
        background: inherit;
        font-family: inherit;
        margin-bottom: 0;
        z-index: 1;
        position: relative;
        // width: 70%;
      }

      & p {
        font-family: inherit;
        margin: 0;
        z-index: 1;
        position: relative;
      }

      &__author {
        width: auto;
      }
    }
  }

  &-headline-inner {
    position: absolute;
    width: 100%;
  }

  &-hidden {
    opacity: 0;
  }

  &-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 34px;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 4rem;
    width: 100%;
    background: transparent;

    @media screen and (max-width: 768px) {
      font-size: 26px;
      text-align: center;
      letter-spacing: -1px;
      padding: 2rem 0rem;
      margin-bottom: 0.7rem;
    }

    &--sticky {
      position: fixed;
      top: $header-height;
      width: 100%;
      z-index: 1000;
      padding: 1rem 0;
      @media screen and (max-width: 768px) {
        padding: 1rem 2.5rem;
        left: 0;
      }
    }

    &-wrapper {
      position: relative;
      left: -3rem;
      @media screen and (max-width: 768px) {
        left: 0;
      }
    }
  }

  &-search-mobile {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  &-scrolltop-mobile {
    display: none;
    @media screen and (max-width: 768px) {
      transition: 0.3s ease-in-out;
      opacity: 0;
      display: block;
    }

    &--sticky {
      opacity: 1;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    height: 100%;
    margin-top: 3rem;
    min-height: 100vh;

    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 768px) {
    & .mobile-hidden {
      display: none;
    }
  }

  &__searches {
    width: 35%;
    padding: 0 4rem;
    margin-top: 7.6rem;
    overflow: auto;

    @media screen and (max-width: 768px) {
      position: absolute !important;
      width: 100%;
      top: 4rem;
      left: 0;
      padding: 3rem 4rem;
      margin: 0;
      margin-top: 2rem;
      height: 80%;
      width: 100%;
      background: white;
      z-index: 1000;
    }

    & p {
      margin-bottom: 0.25rem;
    }

    &-apply-button-mobile {
      @extend .searchresult;
      text-align: center;
      background-color: $dark-blue;
      color: white !important;
      border: none;
      padding: 0.5rem 4rem;
      margin: 0 auto;
      margin-top: 4rem;
      margin-bottom: 2rem;
      display: none;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &-orderby {
      @media screen and (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-title {
          margin-bottom: 0;
        }
      }
      .dropdown-toggle::after {
        position: relative;
        top: 3px;
      }
    }

    &__illustration {
      pointer-events: none;
      min-width: 240px;
      margin-left: -12rem;
      margin-top: -2rem;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &--sticky {
      position: fixed;
      top: 0;
      left: 0;
      margin-top: 9rem;

      @media screen and (max-width: 768px) {
        position: fixed !important;
        top: $header-height;
        margin-top: 0;
      }
    }

    & input[type="text"],
    .autocomplete {
      width: 100%;
      border: 1px solid black;
      border-radius: 0;
      appearance: none;
      color: inherit;
      outline: none;
      padding: 2px 8px;
      padding-top: 2px;
      padding-bottom: 0px;
      padding-left: 2px;
      padding-right: 2px;
      margin-bottom: 8px;
      background: transparent;
      & a {
        color: inherit;
      }

      & .nav-link {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0;
      }
    }

    & .radio-wrapper {
      display: flex;
      align-items: baseline;
      & input {
        margin-right: 6px;
      }
      & label {
        margin-bottom: 0;
      }

      & .nav-link {
        margin-left: 8px;
        padding: 0;
        color: inherit;
      }
    }
  }

  &__list-wrapper {
    position: relative;
    margin-left: auto;
    width: 65%;
    padding: 0 2.5rem;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  & .categories-wrapper {
    display: flex;
    flex-wrap: wrap;
    & .category-tag {
      margin-right: 4px;
      margin-bottom: 4px;
      padding: 0 0.5rem;
      border: 1px solid rgba(0, 0, 0, 0.4);
      & :first-child {
        margin-right: 0.5rem;
      }

      & :last-child {
        cursor: pointer;
      }
    }
  }
}

// make the text perfectly in center of the box border;
.os-mac {
  .stories-item__title {
    padding-top: 0.4rem;
  }
}

// make the text perfectly in center of the box border;
.os-win {
  .stories-item__title {
    padding-bottom: 0.4rem;
  }
  & input[type="text"],
  .autocomplete {
    padding-top: 0px;
    padding-bottom: 4px;
    padding-right: 4px;
  }
  .story-dropdown.dropdown-toggle.nav-link::after {
    position: relative;
    top: 4px;
  }
}

.story-dropdown.dropdown-toggle.nav-link::after {
  position: relative;
  right: 3px;
}

.flex-vertical-center.dropdown-toggle.nav-link {
    text-decoration: underline;
    text-decoration-color: #50e2c1 !important;
}


.stories-item {
  font-family: Amare Medium, sans-serif;
  font-size: 17px;
  margin-bottom: 6rem;
  max-width: 50rem;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  &-content {
    display: flex;
    color: inherit;
    @media screen and (max-width: 768px) {
      display: table-cell;
      vertical-align: bottom;
    }
    flex-direction: row-reverse;
    &__texts {
      width: 100%;
      padding-right: 2rem;
      @media screen and (max-width: 768px) {
        // float: left;
        // clear: both;
      }
    }

    &__image {
      width: 12rem;
      height: 12rem;
      flex-shrink: 0;
      mask-image: url(/svg/post-blob.svg);
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      @media screen and (max-width: 768px) {
        width: 10rem;
        height: 10rem;
        float: right;
        display: block;
      }
    }
  }

  &-meta {
    margin-top: 2rem;
  }

  &-link {
    cursor: pointer;
    text-decoration: underline;
  }

  &__body {
    font-size: 19px;
    line-height: 1.2;

    @media screen and (max-width: 768px) {
      font-size: 17px;
    }
  }

  &__title {
    @extend .searchresult;
    @extend .searchresult-headline;
    color: inherit;
    display: inline-block;
    border: 1px solid black;
    padding: 0.15rem 0.5rem;
    margin-bottom: 0.5rem;
    transition: all 200ms ease;
    background: none;

    &:hover {
      color: #000 !important;
      background: linear-gradient(85.78deg, #A7D0FF -10%, #C2F5E7 42.02%, #d7b8fa 110%);
      border-image: linear-gradient(85.78deg, #A7D0FF -10%, #C2F5E7 42.02%, #d7b8fa 110%);
    }

    @media screen and (max-width: 768px) {
      font-size: 17px;
    }
  }

  &__authors {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1rem;

    & > span {
      margin-right: 4px;
    }
  }

  &__author {
    display: inline;
    font-size: 19px;
    text-decoration: underline;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      font-size: 17px;
    }
  }
}

.story-dropdown {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}


.story-onboarding-item {
  font-family: 'Amare Medium' !important;
  & h3 {
    font-family: 'Amare Medium' !important;
    text-transform: uppercase;
  }
}


.amare-arrow-fix {
  position: relative;
  top: -1.5px;
}

.flex-vertical-center {
  display: flex !important;
  align-items: center;
}
