@import '../../../styles/base/variables.scss';
@import '../../../styles/base/mixins.scss';

.portfolio-search {
  // &-header {
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   position: fixed !important;
  //   pointer-events: none;
  //   z-index: 20;

  //   @include media-breakpoint-down(md) {
  //     display: none;
  //   }
  // }

  // &-sticky-wrapper {
  //   top: 0;
  //   left: 0;
  //   width: 0;
  //   height: 100%;
  //   position: absolute;
  //   z-index: 10;

  //   > div {
  //     top: -90px;
  //     width: 100vw;
  //     position: sticky;
  //     margin-top: $header-height;

  //     @include media-breakpoint-down(sm) {
  //       top: -110px;
  //     }
  //   }
  // }

  display: flex;
  position: relative;
  padding: rem(100px) 0 4rem;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding: rem(60px) 0 4rem;
  }

  &__results {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
    column-gap: 20px;
    padding: 0 rem(48px) rem(32px);
    margin-top: rem(-30px);

    @include media-breakpoint-down(md) {
      padding: 0 var(--page-margin);
    }

    @include media-breakpoint-up(lg) {
      padding-left: rem(20px);
    }

    > p {
      @include subheadlineFont;
    }

    .search-results__item-image {
      a {
        display: block;
        max-width: rem(260px);
        margin: 0 auto;
      }
    }
  }

  .search-filters {
    h2 {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .search-results__item {
    &-image::before {
      display: none;
    }
  }

}