@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.portfolio-detail {
  display: flex;
  font-family: Amare Medium, sans-serif;
  min-height: 100vh;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &__content {
    flex: 1;
    padding: calc(var(--header-height) + 120px) rem(40px) rem(90px);
    overflow: hidden;

    @include media-breakpoint-down(md) {
      padding: rem(60px) var(--page-margin) rem(90px);
    }
    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }

    &-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: rem(15px);
      height: 90%;

      > div {
        display: flex;
        flex-wrap: wrap;
        gap: rem(10px);

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

}

#page-portfolio-detail {
  .alert {
    @include media-breakpoint-up(lg) {
      max-width: calc(100vw - 380px);
      margin: 0 30px 0 auto;
    }
  }
}
