@import '../../../styles/base/variables.scss';
@import '../../../styles/base/mixins.scss';

.portfolios {
  &__intro {
    color: $soft-gray;
    font-size: rem(24px);
    text-align: center;
    font-family: Amare Medium, sans-serif;
    padding: rem(20px) 0 rem(45px);
    background-color: $dark-blue;
  
    @include media-breakpoint-down(sm) {
      padding-bottom: rem(18px);
    }
  
    &-text {
      padding: 0 var(--page-margin);

      h1 {
        @include headlineFont;
        @include media-breakpoint-down(xs) {
          font-size: 28px;
        }
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      max-width: rem(540px);
      margin: rem(40px) auto 0;
      padding: 0 var(--page-margin);

      @include media-breakpoint-down(xs) {
        flex-direction: column;
        gap: rem(20px);
      }
    }
  }

  &__bubbles {
    min-height: rem(500px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-x: auto;

    > div {
      margin: 0 auto;
    }

    @include media-breakpoint-down(xs) {
      min-height: rem(200px);
    }

    &-top, &-bottom {
      display: flex;
      width: max-content;
    }
    &-top {
      padding-right: rem(130px);
      @include media-breakpoint-down(sm) {
        padding-right: rem(100px);
      }
      > *:nth-child(odd) {
        transform: translate3d(0, 30%, 0);
      }
    }
    &-bottom {
      padding-left: rem(130px);
      @include media-breakpoint-down(sm) {
        padding-left: rem(100px);
      }
      > *:nth-child(even) {
        transform: translate3d(0, -10%, 0);
      }
    }
  }

  &__bubble {
    margin: rem(20px);
    min-width: rem(220px);
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      margin: rem(10px);
      min-width: rem(180px);
    }

    svg {
      transition: filter 300ms;
    }

    &:hover {
      svg {
        // filter: drop-shadow(0 0 10px #DEC4FF5C);
        filter: drop-shadow(0 0 10px #0076FF59);
      }
    }
  }
}