@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.tabswitch {
  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-family: Amare Medium, sans-serif;

      button {
        border: none;
        background: transparent;
        color: inherit;
        border: 1px solid;
        border-color: inherit;
        border-right: none;
        white-space: nowrap;
        padding: rem(4px) rem(20px) 0;
        height: rem(36px);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        appearance: none;

        .theme-dark & {
          color: $soft-gray;
          border-color: $soft-gray;
        }
        .theme-light & {
          color: #000;
          border-color: $dark-blue;
        }
      }
        &:first-child button {
          border-top-left-radius: 999px;
          border-bottom-left-radius: 999px;
        }
        &:last-child button {
          border-top-right-radius: 999px;
          border-bottom-right-radius: 999px;
          border: 1px solid;
          .theme-dark & {
            border-color: $soft-gray;
          }
        }

        &.active button {
          .theme-dark & {
            color: #000;
            background: $soft-gray;
            // background: linear-gradient(85.78deg, #A7D0FF -10%, #C2F5E7 42.02%, #d7b8fa 110%);
          }
          .theme-light & {
            color: $soft-gray;
            background: $dark-blue;
            border-color: $dark-blue;
          }
        }
    }
  }
}