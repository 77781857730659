@import '../../styles/base/mixins.scss';

@keyframes floatingBlob {
  0% { transform: translate3d(0, 0, 0) }
  10% { transform: translate3d(20%, 10%, 0) }
  20% { transform: translate3d(30%, 10%, 0) }
  30% { transform: translate3d(0%, -20%, 0) }
  40% { transform: translate3d(-20%, -30%, 0) }
  50% { transform: translate3d(-30%, 10%, 0) }
  60% { transform: translate3d(-20%, 0%, 0) }
  70% { transform: translate3d(10%, -10%, 0) }
  80% { transform: translate3d(20%, 10%, 0) }
  90% { transform: translate3d(30%, 30%, 0) }
  100% { transform: translate3d(0, 0, 0) }
}

.blob {
  padding-top: 100%;
  position: relative;

  &__inner {
    @include absoluteCover;
  }
  &--floating &__inner {
    animation: floatingBlob 40s ease-in-out infinite alternate;
  }
  &--glow &__inner {
    &::before {
      content: "";
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background: radial-gradient(50% 50% at 50% 50%, #9039FE 0%, rgba(144, 57, 254, 0) 90%);
      transform: scale(0.8);
      transition: all 400ms ease;
    }
    &:hover::before {
      transform: none;
    }
  }


  svg {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    position: absolute;
  }

  path {
    cursor: pointer;
    // stroke-width: 2px;
  }
}

