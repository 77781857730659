@import '../../styles/base/variables.scss';
@import '../../styles/base/mixins.scss';

.search-filters {
  padding-left: 3rem;

  @include media-breakpoint-down(md) {
    padding: 0 var(--page-margin);
    height: 0;
    overflow: hidden;

    &.open {
      height: auto;
      padding-bottom: rem(20px);
      border-bottom: 0.5px solid;
      margin-bottom: rem(40px);
    }
  }

  &__inner {
    width: rem(245px);
    font-size: 17px;
    font-family: Amare Alta, sans-serif;
    
    @include media-breakpoint-up(lg) {
      position: sticky;
      overflow-y: auto;
      top: calc($header-height + 135px);
      max-height: calc(100vh - $header-height - 135px);
      padding-right: rem(25px);
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      position: relative;
      top: 0;
    }
  }

  &__selects, &__radios {
    @include media-breakpoint-only(md) {
      display: inline-block;
      width: calc(50% - 40px);
      margin-right: 40px;
    }
  }

  &__legend {
    ul {
      padding: 0;
      margin-bottom: rem(30px);
      list-style: none;

      li {
        opacity: 0.8;
        margin-bottom: rem(10px);

        svg {
          width: rem(30px);
          height: rem(30px);
          margin-right: rem(15px);
        }
      }
    }
  }

  h2 {
    @include headlineFont;
    font-size: 30px;
  }
}
