@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';

@mixin absoluteCover {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

@mixin rainbowLine {
  left: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  background: linear-gradient(to right, #0076FF 0%, #9013FE 20%, #50E3C2 50%);
  height: 5px;
}

@keyframes loadingShimmer {
  from { background-position-x: 150% }
  to { background-position-x: -150% }
}
@mixin loadingShimmer {
  background: linear-gradient(90deg, #FFFFFF00 0, #FFFFFF10 5%, #FFFFFF66 20%, #FFFFFF80 50%, #FFFFFF00 75%, #FFFFFF00 100%) $dark-gray;
  background-size: 300% 100%;
  background-position: right bottom;
  animation: loadingShimmer 3s linear infinite;
  opacity: 0.2;
}

@mixin headlineFont {
  font-size: 34px;
  font-family: Amare Medium, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@mixin subheadlineFont {
  font-size: 24px;
  font-family: Amare Medium, sans-serif;
  letter-spacing: 0.02em;
}

@mixin sansBodyFont {
  font-size: 16px;
  font-family: Amare Alta, sans-serif;
  line-height: 1.2em;
}

@mixin sansBodyCompactFont {
  font-size: 16px;
  font-family: Amare Alta, sans-serif;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}

@mixin sansBodyCompactFontLight {
  font-size: 16px;
  font-family: Amare Medium, sans-serif;
  letter-spacing: -0.02em;
  line-height: 1.2em;
}
